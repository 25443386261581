import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import ax from 'src/utilities/axios'

class VideoPlayer extends Component {

  // React setup
  constructor(props) {
    super(props)
    this.state = {
      expired: false,
      viewerLimitReached: false,
      chapters: [],
    }
  }

  loadChapters = () => {
    ax.get(`/videos/${this.props.id}/chapters.json`)
    .then((data) => {
      this.setState({ chapters: data.data })
    })
    .catch(error => {
      console.error(data)
    })
  }

  goToTime = (e) => {
    e.preventDefault()
    let seconds = $(e.target).data("seconds")
    this.player.currentTime(seconds)
    this.player.play()
    return false
  }

  componentDidMount() {

    if (this.props.player == "vod") this.loadChapters()

    if (this.props.status == "expired") {
      this.setState({expired: true})
    } else {

      this.player = videojs(this.videoNode, {
        responsive: true
      }, () => {
        // videojs.log('onPlayerReady', this);
      })

      this.setupConstantTimeupdate(this.player)

      this.player.on('play', (event) => {
        this.sendPing("play", {playerTime: this.player.currentTime()})
      })

      this.player.on('pause', (event) => {
        this.sendPing("pause", {playerTime: this.player.currentTime()})
      })

      this.player.on('waiting', (event) => {
        this.sendPing("waiting", {playerTime: this.player.currentTime()})
      })

      this.player.on('fullscreenchange', (event) => {
        this.sendPing("fullscreenchange", {playerTime: this.player.currentTime()})
      })

      this.player.on('error', (event) => {
        const error = this.player.error()
        this.sendPing("error", {playerTime: this.player.currentTime(), errorCode: error.code, errorMessage: error.message})
      })

      this.player.on('constant-timeupdate', (event) => {
        if (this.player.currentTime() > 1) {
          this.sendPing("progress", {playerTime: this.player.currentTime()})
        }
      })
    }
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  sendPing(event, options = {}) {
    const _this = this
    ax.post(`/videos/${this.props.id}/ping`, {
      video_view: {
        tokenable_id: (typeof gon != 'undefined') ? gon.tokenable_id : "",
        tokenable_type: (typeof gon != 'undefined') ? gon.tokenable_type : "",
        event: event,
        player_time: options.playerTime,
        error_code: options.errorCode,
        error_message: options.errorMessage,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }
    })
    .then(function (response) {
      // handle success
      // console.log(response)
      if (response.data == "viewer_limit_reached") {
        _this.player.dispose()
        _this.setState({viewerLimitReached: true})
      }
      if (response.data == "expired") {
        _this.player.dispose()
        _this.setState({expired: true})
      }
    })
    .catch(function (error) {
      if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        Rollbar.error("VideoPlayer ping received error from server", error.response)
      } else if (error.request) {
        // The request was made but no response was received
        Rollbar.error("VideoPlayer ping did not receive response from server", error.request)
      }
    })
  }

  setupConstantTimeupdate(player) {
    // from https://www.npmjs.com/package/videojs-constant-timeupdate

    const interval = 1000 * 150
    const roundFn = Math.round

    player.on('play', () => {
      player.clearInterval(this.timeHandler)
      let currentTime = roundFn(player.currentTime())
      this.timeHandler = player.setInterval(() => {
        currentTime = roundFn(player.currentTime())
        if (this.lastTime !== currentTime) {
          player.trigger('constant-timeupdate', currentTime)
          this.lastTime = currentTime
        }
      }, interval)
      if (this.lastTime !== currentTime) {
        player.trigger('constant-timeupdate', currentTime)
        this.lastTime = currentTime
      }
    })
    player.on('pause', () => {
      player.clearInterval(this.timeHandler)
    })
    player.on('ended', () => {
      player.clearInterval(this.timeHandler)
    })
  }


  render() {
    const chaptersList =
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Performer</th>
            <th>Time</th>
          </tr>
        </thead>
        <tbody>
          {this.state.chapters.map(chapter => (
            <tr key={chapter.id}>
              <td>
                <p className="mb-0">
                  <strong><a href="#" onClick={this.goToTime} data-seconds={chapter.player_time}>{chapter.name}</a></strong>
                  {chapter.description.length > 0 ? <br /> : null}
                  {chapter.description.split('\n').map((line, i) => (
                  <span key={i}>{line}<br /></span>
                ))}
                </p>
              </td>
              <td>
                  <a href="#" onClick={this.goToTime} data-seconds={chapter.player_time}>{chapter.timecode}</a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      const VODPlayer =
      <div className="embed-responsive embed-responsive-16by9 mb-3">
        <div data-vjs-player>
          <video
            // id={`video_${this.props.id}_player`}
            ref={node => this.videoNode = node}
            className="video-js vjs-theme-fantasy embed-responsive-item"
            controls
            preload="auto"
            width="640"
            height="264"
            poster={this.props.poster_url}
          >

            <source src={this.props.source_url} type="application/x-mpegURL" />

          </video>
        </div>
      </div>

    const livePlayer =
      <div className="embed-responsive embed-responsive-16by9 mb-3">
        <div data-vjs-player>
          <video
            // id={`video_${this.props.id}_player`}
            ref={node => this.videoNode = node}
            className="video-js vjs-theme-fantasy embed-responsive-item"
            controls
            preload="auto"
            width="640"
            height="264"
            poster={this.props.poster_url}
          >

            <source src={this.props.source_url} type="application/x-mpegURL" />

          </video>
        </div>
      </div>

    const liveDVRPlayer =
      <div className="embed-responsive embed-responsive-16by9 mb-3">
        <div data-vjs-player>
          <video
            // id={`video_${this.props.id}_player`}
            ref={node => this.videoNode = node}
            className="video-js vjs-theme-fantasy embed-responsive-item"
            controls
            preload="auto"
            width="640"
            height="264"
            poster={this.props.poster_url}
          >

            <source src={this.props.source_url} type="application/x-mpegURL" />

          </video>
        </div>
      </div>

    const viewerLimitReachedCard =
      <div className="card text-white bg-dark text-center py-5">
        <div className="card-body">
          <h2>Viewer Limit Reached</h2>
          <p>Your access link/token has been used on another device. Please close the other browser/device and try again here.</p>

          <p><em>As a reminder, each purchase&apos;s access link can be used on only one device at a time.</em></p>
        </div>
      </div>

    const expiredLiveCard =
      <div className="card text-white bg-dark text-center py-5">
        <div className="card-body">
          <h2>Video Has Ended</h2>
          <p>This live stream has ended.</p>
        </div>
      </div>

    const expiredVODCard =
      <div className="card text-white bg-dark text-center py-5">
        <div className="card-body">
          <h2>Video Has Expired</h2>
          <p>This video is no longer available.</p>
        </div>
      </div>

    return (
      <Fragment>
        {(this.state.viewerLimitReached || this.state.expired) ? null : this.props.player == "vod" ? VODPlayer : this.props.player == "live" ? livePlayer : this.props.player == "live_with_dvr" ? liveDVRPlayer : null}
        {this.state.viewerLimitReached ? viewerLimitReachedCard : null}
        {this.state.expired && this.props.player == "vod" ? expiredVODCard : this.state.expired ? expiredLiveCard : null}
        {!(this.state.viewerLimitReached || this.state.expired) && this.state.chapters.length > 0 ? chaptersList : null }
      </Fragment>
    )
  }
}

VideoPlayer.defaultProps = {
  id: 0,
  player: '',
  source_url: '',
  poster_url: '',
  status: '',
}

VideoPlayer.propTypes = {
  id: PropTypes.number,
  player: PropTypes.string,
  source_url: PropTypes.string,
  poster_url: PropTypes.string,
  status: PropTypes.string,
}

export default VideoPlayer
